var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "px-2"
  }, [_c('b-row', [_c('b-col', [_c('validation-observer', {
    ref: "usersValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Profile Image",
      "label-for": "avatar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "profile image",
      "vid": "avatar"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-avatar', {
    ref: "previewEl",
    attrs: {
      "src": _vm.previewAvatar,
      "size": "90px",
      "rounded": ""
    }
  })], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "btn-sm mr-1",
    attrs: {
      "variant": "outline-primary",
      "disabled": !_vm.isUserFormEdit
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.refInputEl.click();
      }
    }
  }, [_c('input', {
    ref: "refInputEl",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "input": _vm.uploadImage
    }
  }), _c('feather-icon', {
    attrs: {
      "icon": "UploadIcon"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-sm mr-1",
    attrs: {
      "variant": "outline-danger",
      "disabled": !_vm.isUserFormEdit
    },
    on: {
      "click": _vm.removeImage
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusSquareIcon"
    }
  })], 1)], 1)], 1), _vm.imageSizeError ? [_c('small', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.imageSizeError))])] : _vm._e()], 2)], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name *",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|max:255")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "name",
            "placeholder": "Enter Full Name",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "National ID *",
      "label-for": "nid"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "national id / social security number",
      "rules": "required",
      "vid": "nid"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "nid",
            "state": errors.length > 0 ? false : null,
            "name": "nid",
            "placeholder": "NID / SSN Number",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.nid,
            callback: function callback($$v) {
              _vm.nid = $$v;
            },
            expression: "nid"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email *",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "email",
      "name": "email",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|email"),
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "email",
            "placeholder": "john@example.com",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Personal Email *",
      "label-for": "personal_email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "email",
      "name": "email",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|email"),
      "vid": "personal_email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "personal_email",
            "state": errors.length > 0 ? false : null,
            "name": "personal email",
            "placeholder": "john@example.com",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.personal_email,
            callback: function callback($$v) {
              _vm.personal_email = $$v;
            },
            expression: "personal_email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile *",
      "label-for": "mobile"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "mobile",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required'),
      "vid": "mobile"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "mobile",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "mobile",
            "placeholder": "Mobile Number",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.mobile,
            callback: function callback($$v) {
              _vm.mobile = $$v;
            },
            expression: "mobile"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date Of Birth *",
      "label-for": "dob"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "date of birth",
      "vid": "dob",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "dob",
            "placeholder": "Select Date",
            "disabled": !_vm.isUserFormEdit,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.dob,
            callback: function callback($$v) {
              _vm.dob = $$v;
            },
            expression: "dob"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Blood Group *",
      "label-for": "blood-group"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "blood group",
      "vid": "blood_group",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "status",
            "options": _vm.bloodGroupTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.selectBloodGroupValue,
            callback: function callback($$v) {
              _vm.selectBloodGroupValue = $$v;
            },
            expression: "selectBloodGroupValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Religion *",
      "label-for": "religion"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "religion",
      "vid": "religion",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "religion",
            "options": _vm.religionValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.selectReligionValue,
            callback: function callback($$v) {
              _vm.selectReligionValue = $$v;
            },
            expression: "selectReligionValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gender *",
      "label-for": "gender"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "gender",
      "vid": "gender",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "gender",
            "placeholder": "Choose a gender",
            "options": _vm.genderOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.selectGenderId,
            callback: function callback($$v) {
              _vm.selectGenderId = $$v;
            },
            expression: "selectGenderId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Marital Status *",
      "label-for": "martial status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "marital status",
      "vid": "marital_status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "marital_status",
            "options": _vm.maritalStatusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here",
            "disabled": !_vm.isUserFormEdit
          },
          model: {
            value: _vm.selectMaritalStatusValue,
            callback: function callback($$v) {
              _vm.selectMaritalStatusValue = $$v;
            },
            expression: "selectMaritalStatusValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.$permissionAbility(_vm.USERS_GENERAL_INFO_EDIT, _vm.permissions) && _vm.user.id === _vm.$route.params.id || _vm.$permissionAbility(_vm.USERS_EDIT, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "id": "editButton"
    },
    on: {
      "click": _vm.enableEditingFields
    }
  }, [!_vm.isUserFormEdit ? [_vm._v(" Edit ")] : _vm.isUserFormEdit ? [_vm._v(" Cancel ")] : _vm._e()], 2)] : _vm._e(), _vm.isUserFormEdit ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save ")])] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }