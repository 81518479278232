<template>
  <b-card class="px-2">
    <b-row>
      <b-col>
        <validation-observer ref="usersValidation">
          <b-form v-on:submit.prevent="validationForm">
            <b-row>
              <!-- name -->

              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Profile Image" label-for="avatar">
                  <validation-provider name="profile image" vid="avatar">
                    <b-row>
                      <b-col xs="6" lg="6" md="6">
                        <b-avatar
                          ref="previewEl"
                          :src="previewAvatar"
                          size="90px"
                          rounded
                        />
                      </b-col>
                      <b-col class="mt-2" xs="6" lg="6" md="6">
                        <b-button
                          variant="outline-primary"
                          class="btn-sm mr-1"
                          @click="$refs.refInputEl.click()"
                          :disabled="!isUserFormEdit"
                        >
                          <input
                            ref="refInputEl"
                            type="file"
                            class="d-none"
                            @input="uploadImage"
                            accept="image/*"
                          />
                          <feather-icon icon="UploadIcon" />
                          <!-- MinusCircleIcon -->
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          class="btn-sm mr-1"
                          @click="removeImage"
                          :disabled="!isUserFormEdit"
                        >
                          <feather-icon icon="MinusSquareIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <template v-if="imageSizeError">
                      <small class="text-danger">{{ imageSizeError }}</small>
                    </template>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Name *" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    :rules="`${
                      modelType == 'editModel' ? '' : 'required'
                    }|max:255`"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      placeholder="Enter Full Name"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- employee_number -->
<!--              <b-col xs="12" lg="4" md="4">-->
<!--                <b-form-group label="Employee ID *" label-for="employee-number">-->
<!--                  <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="employee id"-->
<!--                    vid="employee_number"-->
<!--                    rules="required|max:255"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="employee-number"-->
<!--                      type="text"-->
<!--                      v-model="employeeNumber"-->
<!--                      :state="errors.length > 0 ? false : null"-->
<!--                      placeholder="EM-220001"-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </validation-provider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <!-- nid -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="National ID *" label-for="nid">
                  <validation-provider
                    #default="{ errors }"
                    name="national id / social security number"
                    rules="required"
                    vid="nid"
                  >
                    <b-form-input
                      id="nid"
                      v-model="nid"
                      :state="errors.length > 0 ? false : null"
                      name="nid"
                      placeholder="NID / SSN Number"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- email -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Email *" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    type="email"
                    name="email"
                    :rules="`${
                      modelType == 'editModel' ? '' : 'required'
                    }|email`"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="john@example.com"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Personal Email *" label-for="personal_email">
                  <validation-provider
                    #default="{ errors }"
                    type="email"
                    name="email"
                    :rules="`${
                      modelType == 'editModel' ? '' : 'required'
                    }|email`"
                    vid="personal_email"
                  >
                    <b-form-input
                      id="personal_email"
                      v-model="personal_email"
                      :state="errors.length > 0 ? false : null"
                      name="personal email"
                      placeholder="john@example.com"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Mobile *" label-for="mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    :rules="`${modelType == 'editModel' ? '' : 'required'}`"
                    vid="mobile"
                  >
                    <b-form-input
                      id="mobile"
                      type="number"
                      v-model="mobile"
                      :state="errors.length > 0 ? false : null"
                      name="mobile"
                      placeholder="Mobile Number"
                      :disabled="!isUserFormEdit"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- DOB -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Date Of Birth *" label-for="dob">
                  <ValidationProvider
                    name="date of birth"
                    v-slot="{ errors }"
                    vid="dob"
                    rules="required"
                  >
                    <flat-pickr
                        id="dob"
                        v-model="dob"
                        class="form-control"
                        placeholder="Select Date"
                        :disabled="!isUserFormEdit"
                        :state="errors.length > 0 ? false : null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- Blood Group -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Blood Group *" label-for="blood-group">
                  <ValidationProvider
                    name="blood group"
                    v-slot="{ errors }"
                    vid="blood_group"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="selectBloodGroupValue"
                      :options="bloodGroupTypeConstants"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- religion -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Religion *" label-for="religion">
                  <ValidationProvider
                    name="religion"
                    v-slot="{ errors }"
                    vid="religion"
                    rules="required"
                  >
                    <v-select
                      id="religion"
                      v-model="selectReligionValue"
                      :options="religionValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- Join Date -->
<!--              <b-col xs="12" lg="4" md="4">-->
<!--                <b-form-group label="Join Date *" label-for="join-date">-->
<!--                  <ValidationProvider-->
<!--                    name="join date"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="join_date"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <b-form-datepicker-->
<!--                      id="join-date"-->
<!--                      v-model="joinDate"-->
<!--                      :state="errors.length > 0 ? false : null"-->
<!--                      locale="en-US"-->
<!--                      today-button-->
<!--                      close-button-->
<!--                      reset-button-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                      @context="onContextPurchaseDate"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <!-- select gender-->
              <b-col xs="12" lg="4" md="4">
                <b-form-group label="Gender *" label-for="gender">
                  <ValidationProvider
                    name="gender"
                    v-slot="{ errors }"
                    vid="gender"
                    rules="required"
                  >
                    <v-select
                      id="gender"
                      placeholder="Choose a gender"
                      v-model="selectGenderId"
                      :options="genderOption"
                      :reduce="(option) => option.value"
                      :disabled="!isUserFormEdit"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- martial status -->
              <b-col xs="12" lg="4" md="4">
                <b-form-group
                  label="Marital Status *"
                  label-for="martial status"
                >
                  <ValidationProvider
                    name="marital status"
                    v-slot="{ errors }"
                    vid="marital_status"
                    rules="required"
                  >
                    <v-select
                      id="marital_status"
                      v-model="selectMaritalStatusValue"
                      :options="maritalStatusValueOption"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose Here"
                      class="custom-font"
                      :disabled="!isUserFormEdit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

<!--              <b-col xs="12" lg="4" md="4">-->
<!--                <b-form-group label="Department *" label-for="department-id">-->
<!--                  <ValidationProvider-->
<!--                    name="department"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="department_id"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <v-select-->
<!--                      id="department-id"-->
<!--                      placeholder="Choose a department"-->
<!--                      v-model="selectDepartmentId"-->
<!--                      :options="departmentIdOption"-->
<!--                      :reduce="(option) => option.id"-->
<!--                      label="name"-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <!-- select employeeTypesOption-->
<!--              <b-col xs="12" lg="4" md="4">-->
<!--                <b-form-group label="Role *" label-for="role-id">-->
<!--                  <ValidationProvider-->
<!--                    name="role"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="role_id"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <v-select-->
<!--                      id="role-id"-->
<!--                      placeholder="Choose a role"-->
<!--                      v-model="selectRoleId"-->
<!--                      :options="roleIdOption"-->
<!--                      :reduce="(option) => option.id"-->
<!--                      label="name"-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col xs="12" lg="4" md="4">-->
<!--                <b-form-group-->
<!--                  label="Employee Type "-->
<!--                  label-for="employee-type-id"-->
<!--                >-->
<!--                  <ValidationProvider-->
<!--                    name="employee_type"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="employee_type_id"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <v-select-->
<!--                      id="employee-type-id"-->
<!--                      placeholder="Choose a employee type"-->
<!--                      v-model="selectEmployeeTypesId"-->
<!--                      :options="employeeTypesOption"-->
<!--                      :reduce="(option) => option.id"-->
<!--                      label="name"-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <!-- select leaveTypesOption-->
<!--              <b-col xs="12" lg="6" md="6">-->
<!--                <b-form-group label="Leave Types" label-for="leave-type-id">-->
<!--                  <template v-if="!isUserFormEdit">-->
<!--                    <div class="leave-type">-->
<!--                      <template v-if="this.leaveTypes.length > 0">-->
<!--                        <b-badge-->
<!--                          variant="primary"-->
<!--                          class="badge-glow"-->
<!--                          v-for="item in this.leaveTypes"-->
<!--                          :key="item.id"-->
<!--                          style="margin-right: 10px; margin-bottom: 5px"-->
<!--                        >-->
<!--                          {{ item.name }}-->
<!--                        </b-badge>-->
<!--                      </template>-->
<!--                      <template v-if="this.leaveTypes.length == 0">-->
<!--                        <b-badge variant="danger" class="badge-glow">-->
<!--                          No Leave Type Assigned-->
<!--                        </b-badge>-->
<!--                      </template>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-if="isUserFormEdit">-->
<!--                    <ValidationProvider-->
<!--                      name="leave_type"-->
<!--                      v-slot="{ errors }"-->
<!--                      vid="leave_type_id"-->
<!--                    >-->
<!--                      <v-select-->
<!--                        id="leave-type-id"-->
<!--                        placeholder="Choose leave "-->
<!--                        v-model="selectLeaveTypesIds"-->
<!--                        :options="leaveTypesOption"-->
<!--                        :reduce="(option) => option.id"-->
<!--                        label="name"-->
<!--                        multiple-->
<!--                        class="custom-font"-->
<!--                        :readonly="isUserFormEdit"-->
<!--                      />-->
<!--                      <small class="text-danger">{{ errors[0] }}</small>-->
<!--                    </ValidationProvider>-->
<!--                  </template>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <!-- select status-->
<!--              <b-col xs="12" lg="6" md="6">-->
<!--                <b-form-group label="Status *" label-for="status">-->
<!--                  <ValidationProvider-->
<!--                    name="status"-->
<!--                    v-slot="{ errors }"-->
<!--                    vid="status"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <v-select-->
<!--                      id="status"-->
<!--                      v-model="selectStatusValue"-->
<!--                      :options="statusValueOption"-->
<!--                      :reduce="(option) => option.value"-->
<!--                      label="name"-->
<!--                      class="custom-font"-->
<!--                      :disabled="!isUserFormEdit"-->
<!--                    />-->
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </ValidationProvider>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <!-- submit and reset -->
              <b-col cols="12">
                <!-- Edit & Cancel button -->
                <template v-if="($permissionAbility(USERS_GENERAL_INFO_EDIT, permissions) && user.id === $route.params.id)|| $permissionAbility(USERS_EDIT, permissions)">
                  <b-button
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-on:click="enableEditingFields"
                    id="editButton"
                  >
                    <template v-if="!isUserFormEdit"> Edit </template>
                    <template v-else-if="isUserFormEdit"> Cancel </template>
                  </b-button>
                </template>
                <!-- loading button -->
                <template v-if="isUserFormEdit">
                  <b-button
                    type="submit"
                    class="float-right mr-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Save
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

    <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormDatepicker,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { USERS_EDIT, USERS_GENERAL_INFO_EDIT } from '@/helpers/permissionsConstant'
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max, mimes, size, confirmed } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { bloodGroupTypeConstants } from "@/helpers/constant/bloodGroupTypeConstant";
import { religionTypeConstants as religionValueOption } from "@/helpers/constant/religionTypeConstant";
import { maritalStatusTypeConstants as maritalStatusValueOption } from "@/helpers/constant/maritalStatusTypeConstant";

export default {
  mixins: [togglePasswordVisibility],
  name: "UserEditGeneralDetails",

  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BFormDatepicker,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      USERS_GENERAL_INFO_EDIT,
      USERS_EDIT,
      modelType: "",
      showDecadeNav: true,
      imageSizeError: "",
      editButtonText: "Edit",
      userId: "",
      avatar: null,
      name: "",
      nid: "",
      employeeNumber: "",
      password: "",
      password_confirmation: "",
      email: "",
      personal_email: "",
      mobile: "",
      dob: "",
      selectBloodGroupValue: "",
      bloodGroupTypeConstants,
      selectReligionValue: "",
      religionValueOption,
      selectMaritalStatusValue: "",
      maritalStatusValueOption,
      joinDate: "",
      selectRoleId: "",
      roleIdOption: [],
      selectGenderId: "",
      previewAvatar: null,
      genderOption: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
      ],
      selectStatusValue: true,
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],
      selectDepartmentId: "",
      departmentIdOption: [],
      selectDesignationId: "",
      designationIdOption: [],
      selectEmployeeTypesId: "",
      employeeTypesOption: [],
      selectLeaveTypesIds: [],
      leaveTypesOption: [],
      leaveTypes: [],
      pageLength: 10,

      isUserFormEdit: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",
      permissions: "userModule/getPermissions",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  async created() {
    try {
      const [
        roles,
        departments,
        getEmployeeTypes,
        getLeaveTypesAll,
        userGeneralData,
      ] = await Promise.all([
        this.getRoles(),
        this.getDepartments(),
        this.getEmployeeTypes(),
        this.getLeaveTypesAll(),
        this.getUserGeneralData(),
      ]);

      // map user general data
      this.leaveTypes = userGeneralData?.data?.data?.leaveTypes?.data;

      this.mapUserGeneralData(userGeneralData?.data?.data);

      // roles
      this.roleIdOption = (roles?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          id: item?.id,
        };
      });

      // departments
      this.departmentIdOption = (departments?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          id: item?.id,
        };
      });

      // designations
      this.employeeTypesOption = (getEmployeeTypes?.data?.data || []).map(
        (item) => {
          return {
            name: item?.name,
            id: item?.id,
          };
        }
      );

      //  getLeaveTypesAll
      this.leaveTypesOption = (getLeaveTypesAll?.data?.data || []).map(
        (item) => {
          return {
            name: item?.name,
            id: item?.id,
          };
        }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    enableEditingFields() {
      if (this.isUserFormEdit) {
        this.isUserFormEdit = false;
      } else {
        this.isUserFormEdit = true;
      }
    },
    mapUserGeneralData(data) {
      this.previewAvatar = data?.avatar;
      this.dob = data?.dob;
      this.name = data?.name;
      this.email = data?.email;
      this.personal_email = data?.personal_email;
      this.nid = data?.nid;
      this.employeeNumber = data?.employee_number;
      this.mobile = data?.mobile;
      this.selectStatusValue = data?.status;
      this.joinDate = data?.join_date;
      this.selectReligionValue = data?.religion;
      this.selectBloodGroupValue = data?.blood_group;
      this.selectMaritalStatusValue = data?.marital_status;
      this.selectGenderId = data?.gender;
      this.selectRoleId = data?.roles?.data[0]?.id;
      this.selectDesignationId = data?.designation_id;
      this.selectDepartmentId = parseInt(data?.department_id);
      this.selectEmployeeTypesId = parseInt(data?.employee_type_id);
      this.selectLeaveTypesIds = (data?.leaveTypes?.data || []).map((item) => {
        return item?.id;
      });
      //  this.getUserGeneralData(),
    },
    removeImage() {
      this.previewAvatar = null;
      this.avatar = null;
      this.imageSizeError = "";
    },
    uploadImage() {
      let input = this.$refs.refInputEl;
      let file = input.files;
      if (parseInt(file[0].size) / 1000 <= 2048) {
        this.imageSizeError = "";
        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.previewAvatar = e.target.result;
          };
          reader.readAsDataURL(file[0]);
          this.avatar = file[0];
        }
      } else {
        this.previewAvatar = null;
        this.avatar = null;
        this.imageSizeError = "Profile Image Maximum 2048 kb Allowed";
      }
    },
    onContextPurchaseDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    async getRoles() {
      return await this.$api.get("api/roles/all");
    },

    async getEmployeeTypes() {
      return await this.$api.get("api/employee-types/all");
    },
    async getLeaveTypesAll() {
      return await this.$api.get("api/leave-types/current-year-all");
    },
    async getUserGeneralData() {
      return await this.$api.get(
        `api/users/${this.$route.params.id}?include=department,roles,employeeType,designation,leaveTypes`
      );
    },
    validationForm: async function () {
      this.$refs.usersValidation.validate().then(async (success) => {
        if (success) {
          if (this.imageSizeError == "") {
            try {
              // this.isUserFormEdit = true;

              const formData = new FormData();

              formData.append("_method", "PUT");

              if (this.avatar) {
                formData.append("avatar", this.avatar);
              }

              if (this.name) {
                formData.append("name", this.name);
              }

              if (this.nid) {
                formData.append("nid", this.nid);
              }

              if (this.email) {
                formData.append("email", this.email);
              }

              if (this.personal_email) {
                formData.append("personal_email", this.personal_email);
              }

              if (this.mobile) {
                formData.append("mobile", this.mobile);
              }

              if (this.dob) {
                formData.append("dob", this.dob);
              }

              if (this.joinDate) {
                formData.append("join_date", this.joinDate);
              }

              if (this.selectBloodGroupValue) {
                formData.append("blood_group", this.selectBloodGroupValue);
              }

              if (this.selectReligionValue) {
                formData.append("religion", this.selectReligionValue);
              }

              if (this.selectMaritalStatusValue) {
                formData.append(
                  "marital_status",
                  this.selectMaritalStatusValue
                );
              }

              // formData.append("status", this.selectStatusValue);

              // if (this.password) {
              //   formData.append("password", this.password);
              // }
              //
              // if (this.password_confirmation) {
              //   formData.append(
              //     "password_confirmation",
              //     this.password_confirmation
              //   );
              // }

              // if (this.selectRoleId) {
              //   formData.append("role_id", this.selectRoleId);
              // }
              //
              // if (this.selectDepartmentId) {
              //   formData.append("department_id", this.selectDepartmentId);
              // }

              if (this.selectGenderId) {
                formData.append("gender", this.selectGenderId);
              }

              // if (this.selectEmployeeTypesId) {
              //   formData.append("employee_type_id", this.selectEmployeeTypesId);
              // }
              //
              // if (this.selectLeaveTypesIds.length > 0) {
              //   this.selectLeaveTypesIds.forEach(function (element) {
              //     formData.append("leave_type_id[]", element);
              //   });
              // }

              // if (this.employeeNumber) {
              //   formData.append("employee_number", this.employeeNumber);
              // }

              await this.$api.post(
                `/api/users/update-general/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              this.isUserFormEdit = false;
              const userGeneralData = await this.getUserGeneralData();

              const user = userGeneralData?.data?.data;

              if (this.user.id === user?.id) {
                await this.$store.dispatch("userModule/setUser", {
                  user,
                });
              }

              this.leaveTypes = userGeneralData?.data?.data?.leaveTypes?.data;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } catch (error) {
              // this.isUserFormEdit = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.usersValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        }
      });
    },
  },
};
</script>

    <style lang="scss">
.custom-font {
  font-size: 13px !important;
}

.leave-type {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  background: #f8f8f8;
}

.flatpickr-wrapper{
  width: 100% !important;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
