<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(USERS_SALARY_UPDATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Update
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table salary-table"
        @on-sort-change="onSortChange"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-user-salary-form"
      centered
      title="Update Salary"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="userDesignationValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Activity Type-->
          <b-form-group label="Salary Amount *" label-for="value">
            <ValidationProvider
              name="amount "
              v-slot="{ errors }"
              vid="value"
              rules="required"
            >
              <b-form-input
                id="value"
                type="text"
                v-model="value"
                placeholder="Enter Salary Amount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Date" label-for="date">
            <ValidationProvider
                name="date"
                v-slot="{ errors }"
                vid="date"
                rules="required"
            >
              <flat-pickr
                  id="date"
                  v-model="selectDate"
                  class="form-control"
                  placeholder="Select Date"
                  :state="errors.length > 0 ? false : null"
                  :config="datePickerConfig"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isUserSalaryFormSubmit">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BFormFile,
    BFormTextarea,
    BLink, BFormDatepicker,
  } from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  USERS_SALARY_UPDATE,
  USERS_SALARY_DELETE,
} from "@/helpers/permissionsConstant";
  import flatPickr from 'vue-flatpickr-component'

export default {
  name: "UserSalaryView",
  components: {
    BFormDatepicker,
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BFormTextarea,
    BSpinner,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      USERS_SALARY_UPDATE,
      USERS_SALARY_DELETE,

      value: "",
      selectDate: "",
      datePickerConfig: {static: true},


      pageLength: 10,
      columns: [
        {
          label: "Amount",
          field: "value",
          sortable: false,
        },

        {
          label: "Date",
          field: "effective_date",
          formatFn: this.formatFnTableLastContactDate,
        },
        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatFnTableLastContactDate,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserSalaryFormSubmit: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    // this.loadItems();
  },

  methods: {
    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
    async getDesignations() {
      return await this.$api.get("api/designations/all");
    },
    showModal() {
      this.$bvModal.show("modal-user-salary-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-user-salary-form");
      this.resetModal();
    },
    resetModal() {
      this.value = "";
      this.selectDate = "";
    },

    async onDelete(row) {
      const id = row.id
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To Delete ${row.name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();

            formData.append("_method", "delete");

            if (this.$route.params.id) {
              formData.append("user_id", this.$route.params.id);
            }
            await this.$api.post(`/api/user-bank-account/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getUserHistory(params) {
      return await this.$api.get(
        `api/user-salary-history/user/${this.$route.params.id}`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },

    async loadItems() {
      try {
        const [userHistory] = await Promise.all([
          this.getUserHistory({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = userHistory?.data?.data;
        const meta = userHistory?.data?.meta;

        this.rows = data;
        this.totalRecords = meta?.pagination?.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    validationForm: async function () {
      this.$refs.userDesignationValidation.validate().then(async (success) => {
        if (success) {
          this.isUserSalaryFormSubmit = true;
          try {
            await this.$api.post(`/api/users/update-salary`, {
              user_id: this.$route.params.id,
              activity: "salary_change",
              value: this.value,
              effective_date: this.selectDate,
            });
            this.isUserSalaryFormSubmit = false;
            this.hiddenModal();
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Updated",
              },
            });
          } catch (error) {
            this.isUserSalaryFormSubmit = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.userDesignationValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
.salary-table {
  min-height: 137px !important;

  font-size: 13px !important;
}
.salary-table thead th,
.salary-table tbody td {
  text-align: center;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
